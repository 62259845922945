import React from "react"
import MockupAnimation from "../animations/MockupAnimation"
import PurchaseButton from "../buttons/PurchaseButton"
import WaveBackground from "../backgrounds/WaveBackground"
import { themes } from "../styles/ColorStyles"
import { H1, MediumText } from "../styles/TextStyles"
import styled, { keyframes } from "styled-components"

const skillData = [
  { title: "", icon: "/images/logos/sketch-logo.svg" },
  { title: "", icon: "/images/logos/figma-logo.svg" },
  { title: "", icon: "/images/logos/webflow-logo.svg" },
  { title: "", icon: "/images/logos/flutter-logo.svg" },
  { title: "", icon: "/images/logos/swiftui-logo.svg" },
]
function HeroSection() {
  return (
    <Wrapper>
      <WaveBackground />
      <ContentWrapper>
        <TextWrapper>
          <Title>
            Hi, I'm
            <br /> <span>魏</span>峥 <br />
            Hello world
          </Title>
          <Description>
            {/* Don’t skip design. Learn design and code by building real apps with
            React and Swift. Complete courses about the best tools. */}
          </Description>
          <PurchaseButton /*title="XXXXXXXXX" subtitle="xxxx" */ />
          <Skillwrapper>
            {skillData.map(item => (
              <p>
                <img src={item.icon} alt={item.title} />
                {item.title}
              </p>
            ))}
          </Skillwrapper>
        </TextWrapper>
        <MockupAnimation />
      </ContentWrapper>
    </Wrapper>
  )
}

export default HeroSection
const animation = keyframes`
   0% { opacity: 0; transform: translateY(-10px); filter: blur(10px); }
  100% { opacity: 1; transform: translateY(0px); filter: blur(0px); }
`
const Wrapper = styled.div`
  // height: 920px;
  overflow: hidden;
`
const ContentWrapper = styled.div`
  max-width: 1234px;
  bottom: 200px;
  margin: 0 auto;
  padding: 200px 30px;
  display: grid;
  grid-template-columns: 360px auto;
  @media (max-width: 450px) {
    grid-template-columns: auto;
    padding: 150px 20px 250px;
    gap: 60px;
  } ;
`
const TextWrapper = styled.div`
  max-width: 360px;
  display: grid;
  gap: 30px;
  > * {
    :nth-child(1) {
      animation: ${animation} 1s 0s forwards;
    }
    :nth-child(2) {
      animation: ${animation} 1s 0.4s forwards;
    }
    :nth-child(3) {
      animation: ${animation} 1s 0.6s forwards;
    }
  }
`
const Title = styled(H1)`
  color: ${themes.dark.text1};
  opacity: 0;
  background: linear-gradient(180deg, #730040 0%, #301cbe 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  span {
    background: linear-gradient(180deg, #ffd7ff 0%, #ffb6ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }

  @media (max-width: 450px) {
    font-size: 48px;
  }
`
const Description = styled(MediumText)``
const Skillwrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
`
